import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Box } from "@mui/material";

const crumbNames = {
  dashboard: "Dashboard",
  relatorios: "Relatórios",
  "novo-relatorio": "Novo relatório",
  "editar-visualizar": "Editar/Visualizar",
  "editar-relatorio": "Editar relatório",
  associacoes: "Associações",
};

const formatCrumb = (crumb) => {
  const reportIdPattern = /^\d{4}-\d{4}$/;
  const isNumber = /^\d+$/.test(crumb);

  if (reportIdPattern.test(crumb)) {
    return `Relatório ${crumb.replace("-", "/")}`;
  }

  if (isNumber) {
    return `Relatório ${crumb}`;
  }

  return crumbNames[crumb] || crumb;
};

export default function Breadcrumb() {
  const location = useLocation();

  let currentLink = "";

  const pathCrumbs = location.pathname
    .split("/")
    .filter((crumb) => crumb !== "");

  const crumbs = pathCrumbs.map((crumb, index) => {
    currentLink += `/${crumb}`;
    const crumbName = formatCrumb(crumb);
    const isLastCrumb = index === pathCrumbs.length - 1;

    return (
      // eslint-disable-next-line react/no-array-index-key
      <li className="crumb" key={index}>
        <i className="icon fas fa-chevron-right" />
        {isLastCrumb ? (
          <span>{crumbName}</span>
        ) : (
          <Link to={currentLink}>{crumbName}</Link>
        )}
      </li>
    );
  });

  return (
    <Box marginTop={2} className="br-breadcrumb">
      <ul className="crumb-list">
        <li className="crumb home">
          {/* eslint-disable-next-line react/jsx-no-script-url,jsx-a11y/anchor-is-valid,no-script-url */}
          <Link className="br-button circle" to="/relatorios">
            <span className="sr-only">Página inicial</span>
            <i className="fas fa-home" />
          </Link>
        </li>
        {crumbs}
      </ul>
    </Box>
  );
}
