import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useLoaderData, useNavigate, useLocation } from "react-router-dom";
import TableComponent from "../../components/Table/TableComponent";
import ModalFilter from "../../components/ModalFilter/ModalFilter";
import Filter from "../../components/Filter/Filter";
import ButtonComponent from "../../components/ButtonComponent/ButtonComponent";
import GenericService from "../../services/GenericService";
import { getUserInfoFromToken } from "../../services/Auth";

export const reportsLoader = async () => {
  const user = getUserInfoFromToken();
  if (!user) {
    return false;
  }
  const teamNumber = user.team.replace("RF", "");
  try {
    const responseReports = await GenericService.findAllList(
      `listall-report?size=${5}`,
    );
    const responseSupervisor = await GenericService.findAllList(
      `team/supervisor/${teamNumber}`,
    );
    const responsePesquisador = await GenericService.findAllList(
      `team/investigate/${teamNumber}`,
    );
    const responseHomologateReports = await GenericService.findAllList(
      `report-filter?statusFilter=2&page=1&order=desc`,
    );
    const responseElaborationReports = await GenericService.findAllList(
      `report-filter?statusFilter=1&page=1&order=desc`,
    );
    const responseTypesReport = await GenericService.findAllList("type-report");
    const responseTeams = await GenericService.findAllList("teams");
    const responseStatus = await GenericService.findAllList("status");
    return {
      reports: responseReports.data,
      homologateReports: responseHomologateReports.data,
      elaborationReports: responseElaborationReports.data,
      authors: responsePesquisador.data,
      supervisors: responseSupervisor.data,
      typesReport: responseTypesReport.data,
      teams: responseTeams.data,
      status: responseStatus.data,
    };
  } catch (error) {
    return {
      reports: [],
      authors: [],
      supervisors: [],
      typesReport: [],
      teams: [],
      status: [],
      error:
        "Houve um erro ao carregar os dados. Por favor, tente novamente mais tarde.",
    };
  }
};

export default function ReportsList() {
  const [openModal, setOpenModal] = useState(false);
  const [filters, setFilters] = useState("");
  const [arrayFilters, setArrayFilters] = useState([]);
  const [reportsData, setReportsData] = useState(useLoaderData().reports);
  const [pageSize, setPageSize] = useState(5);
  const [page, setPage] = useState(0);
  const [sortInfo, setSortInfo] = useState({ id: "", type: "" });
  const [pageElaboration, setPageElaboration] = useState(0);
  const [pageSizeElaboration, setPageSizeElaboration] = useState(5);
  const [pageHomologation, setPageHomologation] = useState(0);
  const [pageSizeHomologation, setPageSizeHomologation] = useState(5);
  const [activeTab, setActiveTab] = useState("panel-1-elaboration");
  const [homologateReports, setHomologateReports] = useState(
    useLoaderData().homologateReports,
  );
  const [elaborationReports, setElaborationReports] = useState(
    useLoaderData().elaborationReports,
  );
  const dataModal = useLoaderData();

  const navigate = useNavigate();

  const userInfo = getUserInfoFromToken();
  const userRoles = userInfo ? userInfo.roles : [];
  const location = useLocation();

  const hasRequiredRole = userRoles?.some((role) =>
    ["supervisor"].includes(role),
  );

  const columns = [
    { id: "creationDate", label: "Data", date: true, isSort: true },
    { id: "id", label: "ID", isSort: true },
    { id: "title", label: "Título", isSort: true },
    { id: "typeReport", label: "Tipo" },
    { id: "researcher", label: "Pesquisador" },
    { id: "supervidor", label: "Supervisor" },
    { id: "team", label: "Equipe" },
    { id: "status", label: "Status" },
  ];

  async function requestReports(params) {
    try {
      const endpoint =
        activeTab === "panel-3-all"
          ? `listall-report?${params}`
          : `report-filter?${params}`;

      const response = await GenericService.findAllList(endpoint);

      return response.status === 200 ? response.data : [];
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      return [];
    }
  }

  // eslint-disable-next-line no-shadow
  async function requestReportsFilter({ page, size }, params) {
    try {
      const payload = Object.entries(params).map(([key, value]) => {
        if (typeof value === "object" && value !== null && "id" in value) {
          return `${key}=${value.id}`;
        }
        return `${key}=${value}`;
      });

      // Adiciona statusFilter conforme a aba ativa
      if (activeTab === "panel-1-elaboration") {
        payload.push("statusFilter=1");
      } else if (activeTab === "panel-2-homologation") {
        payload.push("statusFilter=2");
      }

      // Adiciona paginação e tamanho de página
      if (page) {
        payload.push(`page=${page}`);
      }

      if (size) {
        payload.push(`size=${size}`);
      }

      if (sortInfo.type) {
        if (sortInfo.id === "creationDate" && activeTab !== "panel-3-all") {
          payload.push(`order=${sortInfo.type}`);
        } else {
          payload.push(`sortBy=${sortInfo.id}`);
          payload.push(`order=${sortInfo.type}`);
        }
      }

      const response = await GenericService.findAllList(
        `report-filter?${payload.join("&")}`,
      );

      if (response.status === 200) {
        return response.data;
      }
      return [];
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      return [];
    }
  }

  const loadReports = async () => {
    let query = "";
    let reports = [];

    switch (activeTab) {
      case "panel-1-elaboration":
        query = `page=${pageElaboration}&size=${pageSizeElaboration}&statusFilter=1`;
        if (!sortInfo.type) {
          query += "&order=desc";
        }
        break;
      case "panel-2-homologation":
        query = `page=${pageHomologation}&size=${pageSizeHomologation}&statusFilter=2`;
        if (!sortInfo.type) {
          query += "&order=desc";
        }
        break;
      case "panel-3-all":
        query = `page=${page}&size=${pageSize}`;
        break;
      default:
        query = `page=${page}&size=${pageSize}`;
        break;
    }

    if (sortInfo.type) {
      if (sortInfo.id === "creationDate" && activeTab !== "panel-3-all") {
        query += `&order=${sortInfo.type}`;
      } else {
        query += `&sortBy=${sortInfo.id}&order=${sortInfo.type}`;
      }
    }

    if (filters && Object.keys(filters).length > 0) {
      let pageSizeToUse = pageSize;
      let pageToUse = page;

      if (activeTab === "panel-1-elaboration") {
        pageSizeToUse = pageSizeElaboration;
        pageToUse = pageElaboration;
      } else if (activeTab === "panel-2-homologation") {
        pageSizeToUse = pageSizeHomologation;
        pageToUse = pageHomologation;
      }

      reports = await requestReportsFilter(
        { page: pageToUse, size: pageSizeToUse },
        filters,
      );
    } else {
      reports = await requestReports(query);
    }

    if (reports) {
      switch (activeTab) {
        case "panel-1-elaboration":
          setElaborationReports(reports);
          break;
        case "panel-2-homologation":
          setHomologateReports(reports);
          break;
        case "panel-3-all":
          setReportsData(reports);
          break;
        default:
          setReportsData(reports);
          break;
      }
    }
  };

  useEffect(() => {
    const load = async () => {
      await loadReports();
    };
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    page,
    pageSize,
    sortInfo,
    filters,
    activeTab,
    pageElaboration,
    pageSizeElaboration,
    pageHomologation,
    pageSizeHomologation,
  ]);

  const handleNewReport = () => {
    navigate("/relatorios/novo-relatorio");
  };

  const handleChangePage = (newPage) => {
    if (filters && Object.keys(filters).length > 0) {
      switch (activeTab) {
        case "panel-1-elaboration":
          setPageElaboration(newPage);
          break;
        case "panel-2-homologation":
          setPageHomologation(newPage);
          break;
        case "panel-3-all":
          setPage(newPage);
          break;
        default:
          setPage(newPage);
          break;
      }
    } else {
      switch (activeTab) {
        case "panel-1-elaboration":
          setPageElaboration(newPage);
          break;
        case "panel-2-homologation":
          setPageHomologation(newPage);
          break;
        case "panel-3-all":
          setPage(newPage);
          break;
        default:
          setPage(newPage);
          break;
      }
    }
  };

  const handleChangeSize = (newSize) => {
    if (filters && Object.keys(filters).length > 0) {
      switch (activeTab) {
        case "panel-1-elaboration":
          setPageSizeElaboration(newSize);
          break;
        case "panel-2-homologation":
          setPageSizeHomologation(newSize);
          break;
        case "panel-3-all":
          setPageSize(newSize);
          break;
        default:
          setPageSize(newSize);
          break;
      }
    } else {
      switch (activeTab) {
        case "panel-1-elaboration":
          setPageSizeElaboration(newSize);
          break;
        case "panel-2-homologation":
          setPageSizeHomologation(newSize);
          break;
        case "panel-3-all":
          setPageSize(newSize);
          break;
        default:
          setPageSize(newSize);
          break;
      }
    }
  };

  const handleSortChange = (sortInfoTable) => {
    const sortId = sortInfoTable.id === "title" ? "tittle" : sortInfoTable.id;
    setSortInfo({ id: sortId, type: sortInfoTable.type });
  };

  const handleModal = async (dataFilter) => {
    setPageSize(5);
    setPage(0);

    if (activeTab === "panel-1-elaboration") {
      // eslint-disable-next-line no-param-reassign
      dataFilter.statusFilter = 1;
    } else if (activeTab === "panel-2-homologation") {
      // eslint-disable-next-line no-param-reassign
      dataFilter.statusFilter = 2;
    }

    const response = await requestReportsFilter(
      { page: 0, size: 5 },
      dataFilter,
    );

    if (activeTab === "panel-3-all") {
      setFilters(dataFilter);
    } else {
      const { statusFilter, ...restFilters } = dataFilter;
      setFilters(restFilters);
    }

    setReportsData(response);
  };

  const clearFilters = async () => {
    setPageSize(5);
    setPage(0);
    const data = await reportsLoader();
    setFilters("");
    setArrayFilters([]);
    setReportsData(data.reports);
  };

  const removeFilter = async (key, value) => {
    setPageSize(5);
    setPage(0);
    // Remove o filtro do arrayFilters
    setArrayFilters((currentFilters) =>
      currentFilters.filter(
        (filter) => filter.key !== key || filter.value !== value,
      ),
    );

    // Remove o filtro do objeto filters
    await setFilters((currentFilters) => {
      const newFilters = { ...currentFilters };
      delete newFilters[key];
      return newFilters;
    });
  };

  const handleReportInfo = (id) => {
    navigate(`${id}`);
  };

  const handleReportViewEdit = (id) => {
    navigate(`${id}/editar-visualizar`, {
      state: {
        location: location.key,
      },
    });
  };

  function formatDate(date) {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
  };

  useEffect(() => {
    if (filters !== undefined) {
      const entries = Object.entries(filters);

      if (entries.length === 0) {
        clearFilters();
      } else {
        const array = entries
          .filter(([key, value]) => {
            // Exclui 'statusFilter' se não estiver na aba 'panel-3-all'
            if (activeTab !== "panel-3-all" && key === "statusFilter") {
              return false;
            }
            return key && value !== undefined;
          })
          .map(([key, value]) => {
            let displayValue;
            if (typeof value === "object") {
              // eslint-disable-next-line prefer-destructuring
              displayValue = Object.values(value)[1]; // Pega o valor da segunda chave do objeto
            } else if (value instanceof Date) {
              displayValue = formatDate(value); // Formata a data
            } else if (key === "archived" && value === true) {
              displayValue = "Sim"; // Trata o caso especial do archived
            } else {
              displayValue = value;
            }
            return { key, value: displayValue };
          });
        setArrayFilters(array);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, activeTab]);

  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        gap={2}
        marginY={3}
      >
        <Typography variant="h1" alignSelf="center">
          Meus relatórios
        </Typography>
        <Box display="flex" gap={2}>
          <ButtonComponent
            title={`Filtrar ${
              arrayFilters.length > 0 ? `(${arrayFilters.length})` : ""
            }`}
            buttonType="secondary"
            icon="filter"
            onClick={() => setOpenModal(true)}
          />
          {hasRequiredRole && (
            <ButtonComponent
              title="Novo relatório"
              icon="new"
              onClick={handleNewReport}
            />
          )}
        </Box>
      </Box>
      <Box>
        {filters && arrayFilters.length > 0 && (
          <Box
            display="flex"
            gap={1}
            alignItems="center"
            flexWrap="wrap"
            marginY={2}
          >
            {arrayFilters.map((filter) => (
              <Filter
                key={filter.key}
                name={filter.key}
                value={filter.value}
                onRemove={() => removeFilter(filter.key, filter.value)}
              />
            ))}
            <Button
              variant="contained"
              sx={{
                borderRadius: 5,
                textTransform: "none",
                padding: "8px 24px 8px 24px",
              }}
              onClick={clearFilters}
            >
              Limpar filtros
            </Button>
          </Box>
        )}

        <Box className="br-tab">
          <Box className="tab-nav">
            <ul>
              <li
                className={`tab-item ${
                  activeTab === "panel-1-elaboration" ? "is-active" : ""
                }`}
                title="Em elaboração"
              >
                <button
                  type="button"
                  onClick={() => handleTabChange("panel-1-elaboration")}
                  data-panel="panel-1-elaboration"
                >
                  <span className="name">Em elaboração</span>
                </button>
              </li>
              <li
                className={`tab-item ${
                  activeTab === "panel-2-homologation" ? "is-active" : ""
                }`}
                title="Aguardando homologação"
              >
                <button
                  type="button"
                  onClick={() => handleTabChange("panel-2-homologation")}
                  data-panel="panel-2-homologation"
                >
                  <span className="name">Aguardando homologação</span>
                </button>
              </li>
              <li
                className={`tab-item ${
                  activeTab === "panel-3-all" ? "is-active" : ""
                }`}
                title="Todos"
              >
                <button
                  type="button"
                  onClick={() => handleTabChange("panel-3-all")}
                  data-panel="panel-3-all"
                >
                  <span className="name">Todos</span>
                </button>
              </li>
            </ul>
          </Box>

          <Box className="tab-content">
            <div
              className={`tab-panel ${
                activeTab === "panel-1-elaboration" ? "active" : ""
              }`}
              id="panel-1-elaboration"
            >
              <TableComponent
                columns={columns}
                actions={["info"]}
                data={elaborationReports}
                changeSize={(size) => handleChangeSize(size)}
                changePage={(pageTable) => handleChangePage(pageTable)}
                onClickInfo={(id) => handleReportInfo(id)}
                onClickEdit={(id) => handleReportViewEdit(id)}
                onClickView={(id) => handleReportViewEdit(id)}
                onSortChange={handleSortChange}
              />
            </div>
            <div
              className={`tab-panel ${
                activeTab === "panel-2-homologation" ? "active" : ""
              }`}
              id="panel-2-homologation"
            >
              <TableComponent
                columns={columns}
                actions={["info"]}
                data={homologateReports}
                changeSize={(size) => handleChangeSize(size)}
                changePage={(pageTable) => handleChangePage(pageTable)}
                onClickInfo={(id) => handleReportInfo(id)}
                onClickEdit={(id) => handleReportViewEdit(id)}
                onClickView={(id) => handleReportViewEdit(id)}
                onSortChange={handleSortChange}
              />
            </div>
            <div
              className={`tab-panel ${
                activeTab === "panel-3-all" ? "active" : ""
              }`}
              id="panel-3-all"
            >
              <TableComponent
                columns={columns}
                actions={["info"]}
                data={reportsData}
                changeSize={(size) => handleChangeSize(size)}
                changePage={(pageTable) => handleChangePage(pageTable)}
                onClickInfo={(id) => handleReportInfo(id)}
                onClickEdit={(id) => handleReportViewEdit(id)}
                onClickView={(id) => handleReportViewEdit(id)}
                onSortChange={handleSortChange}
              />
            </div>
          </Box>
        </Box>
      </Box>

      <ModalFilter
        openModal={openModal}
        closeModal={() => setOpenModal(false)}
        onFormSubmit={(formData) => handleModal(formData)}
        dataModal={dataModal}
        hideStatus={activeTab !== "panel-3-all"}
      />
    </Box>
  );
}
