import { Modal } from "antd";
import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import ButtonComponent from "../ButtonComponent/ButtonComponent";
import DataPickerComponent from "../DataPickerComponent/DataPickerComponent";

export default function HomologReportModal({
  openModal,
  closeModal,
  handleHomolog,
  content,
}) {
  const { control, handleSubmit } = useForm({});

  const onSubmit = (data) => {
    handleHomolog(data);
    closeModal();
  };

  return (
    <Modal
      title={
        <span style={{ fontFamily: "Rawline", fontWeight: 600 }}>
          Homologar relatório
        </span>
      }
      open={openModal}
      width={600}
      onOk={closeModal}
      onCancel={closeModal}
      footer={null}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          display="flex"
          flexDirection="column"
          marginTop={3}
          gap={2}
          // style={{ fontFamily: "Rawline" }}
        >
          <Box display="flex" flexDirection="column">
            <Typography variant="p">Relatório #{content.id}</Typography>
            <Typography variant="p6">{content.title}</Typography>
          </Box>
          <Typography variant="p">
            Ao homologar um relatório, ele se torna disponível para consulta no
            repositório do SIGAR.
          </Typography>
          <Typography variant="p">
            Escolha uma data para disponibilização ou deixe o campo vazio para
            disponibilizar imediatamente.
          </Typography>
          <Grid container>
            <Grid item xs={4}>
              <Controller
                name="Data inicial"
                control={control}
                render={({ field }) => (
                  <DataPickerComponent
                    label="Data de disponibilização:"
                    placeHolder="DD/MM/YYYY"
                    // disabled={isPlaying}
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="flex-end" marginTop={1} gap={2}>
            <ButtonComponent
              buttonType="secondary"
              title="Cancelar"
              onClick={closeModal}
            />
            <ButtonComponent
              title="Homologar"
              icon="check"
              color="error"
              submit
            />
          </Box>
        </Box>
      </form>
    </Modal>
  );
}
