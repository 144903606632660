import { matchPath, Outlet, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { ToastContainer } from "react-toastify";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import Header from "../../components/Header/Header";

export default function DefaultLayout() {
  return (
    <div>
      <Header />

      <Box padding="0 40px 0 40px">
        <Breadcrumb />
        <Outlet />
      </Box>

      <ToastContainer />
    </div>
  );
}
