import React, { useState } from "react";
import {
  alpha,
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faCaretUp,
  faEdit,
  faEye,
  faInfoCircle,
  faSort,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { format, parseISO } from "date-fns";
import TableFooter from "./TableFooter";
import theme from "../../theme/theme";

function FontAwesomeSortIcon({ orderDirection, isActive, isHovered }) {
  let icon;
  if (isActive) {
    icon = orderDirection === "asc" ? faCaretUp : faCaretDown;
  } else {
    icon = faSort;
  }

  const iconOpacity = isActive || isHovered ? 1 : 0;
  return <FontAwesomeIcon icon={icon} style={{ opacity: iconOpacity }} />;
}

function CustomTableSortLabel({
  active,
  direction,
  valueToOrderBy,
  columnId,
  ...restProps
}) {
  const [hover, setHover] = useState(false);

  return (
    <TableSortLabel
      active={active}
      direction={direction}
      IconComponent={() => (
        <FontAwesomeSortIcon
          isActive={valueToOrderBy === columnId}
          orderDirection={direction}
          isHovered={hover}
        />
      )}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      {...restProps}
    />
  );
}

function formatDate(dateString) {
  const date = parseISO(dateString);
  return format(date, "dd/MM/yyyy");
}

export default function TableComponent({
  columns,
  actions,
  data,
  onClickInfo,
  onClickView,
  onClickEdit,
  onClickDelete,
  changeSize,
  changePage,
  onSortChange,
}) {
  const [orderDirection, setOrderDirection] = useState("");
  const [valueToOrderBy, setValueToOrderBy] = useState("");

  const handleRequestSort = (property) => {
    let newOrderDirection = "asc";
    if (valueToOrderBy === property) {
      if (orderDirection === "asc") {
        newOrderDirection = "desc";
      } else if (orderDirection === "desc") {
        newOrderDirection = "";
      }
    }

    setOrderDirection(newOrderDirection);
    setValueToOrderBy(newOrderDirection ? property : "");

    if (onSortChange) {
      onSortChange({
        id: property,
        type: newOrderDirection,
      });
    }
  };

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                style={{ minWidth: column.minWidth }}
                sx={{
                  "&:hover": {
                    backgroundColor: alpha("#1351b4", 0.18),
                  },
                  color: theme.customColors.blueWarm700,
                }}
              >
                {column.isSort ? (
                  <CustomTableSortLabel
                    active={valueToOrderBy === column.id}
                    direction={
                      valueToOrderBy === column.id ? orderDirection : "asc"
                    }
                    onClick={() => handleRequestSort(column.id)}
                    valueToOrderBy={valueToOrderBy}
                    columnId={column.id}
                    sx={{
                      "&:hover": {
                        color: theme.customColors.blueWarm700,
                      },
                      display: "flex",
                    }}
                  >
                    <Box
                      sx={{
                        flexGrow: 1,
                        display: "flex",
                        fontWeight: 600,
                      }}
                    >
                      {column.label}
                    </Box>
                  </CustomTableSortLabel>
                ) : (
                  <Box
                    sx={{
                      flexGrow: 1,
                      display: "flex",
                      fontWeight: 600,
                    }}
                  >
                    {column.label}
                  </Box>
                )}
              </TableCell>
            ))}
            {actions && (
              <TableCell
                sx={{
                  "&:hover": {
                    backgroundColor: alpha("#1351b4", 0.18),
                  },
                  color: theme.customColors.blueWarm700,
                  fontWeight: 600,
                }}
              >
                Ações
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {data && data.content && data.content.length > 0 ? (
            data.content.map((row) => (
              <TableRow hover key={row.id}>
                {columns.map((column) => {
                  let value = row[column.id];
                  if (column.format) {
                    value = column.format(value);
                  } else if (column.date) {
                    value = formatDate(value);
                  }
                  return <TableCell key={column.id}>{value}</TableCell>;
                })}
                {actions && (
                  <TableCell>
                    {actions.includes("info") && (
                      <IconButton
                        sx={{
                          "&:hover": {
                            backgroundColor: theme.customColors.blueWarm200,
                          },
                          "&:active": {
                            backgroundColor: theme.customColors.blueWarm200,
                          },
                        }}
                        onClick={() => onClickInfo(row.id)}
                      >
                        <Tooltip title="Detalhes">
                          <FontAwesomeIcon
                            icon={faInfoCircle}
                            color={theme.customColors.blueWarm700}
                          />
                        </Tooltip>
                      </IconButton>
                    )}
                    {(row.status?.includes("Arquivado") ||
                      row.status?.includes("Homologado") ||
                      row.status?.includes("Aguardando Homologação") ||
                      actions.includes("view")) && (
                      <IconButton
                        sx={{
                          "&:hover": {
                            backgroundColor: theme.customColors.blueWarm200,
                          },
                          "&:active": {
                            backgroundColor: theme.customColors.blueWarm200,
                          },
                        }}
                        onClick={() => onClickView(row.id)}
                      >
                        <Tooltip title="Visualizar">
                          <FontAwesomeIcon
                            icon={faEye}
                            color={theme.customColors.blueWarm700}
                          />
                        </Tooltip>
                      </IconButton>
                    )}

                    {(row.status?.includes("Em elaboração") ||
                      row.status?.includes("Reaberto") ||
                      actions.includes("edit")) && (
                      <IconButton
                        sx={{
                          "&:hover": {
                            backgroundColor: theme.customColors.blueWarm200,
                          },
                          "&:active": {
                            backgroundColor: theme.customColors.blueWarm200,
                          },
                        }}
                        onClick={() => onClickEdit(row.id)}
                      >
                        <Tooltip title="Editar">
                          <FontAwesomeIcon
                            icon={faEdit}
                            color={theme.customColors.blueWarm700}
                          />
                        </Tooltip>
                      </IconButton>
                    )}

                    {actions.includes("delete") && (
                      <IconButton
                        sx={{
                          "&:hover": {
                            backgroundColor: theme.customColors.blueWarm200,
                          },
                          "&:active": {
                            backgroundColor: theme.customColors.blueWarm200,
                          },
                        }}
                        onClick={() => onClickDelete(row.id)}
                      >
                        <Tooltip title="Excluir">
                          <FontAwesomeIcon
                            icon={faTrash}
                            color={theme.customColors.blueWarm700}
                          />
                        </Tooltip>
                      </IconButton>
                    )}
                  </TableCell>
                )}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell
                colSpan={columns.length + (actions ? 1 : 0)}
                align="center"
              >
                Sem dados
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <TableFooter
        count={data?.totalElements || 1} // Total de linhas
        currentPage={data?.number || 0} // Página atual
        totalPages={data?.totalPages || 1} // Total de páginas
        rowsPerPage={data?.size || 5} // Linhas por página
        setCurrentPage={(pageNumber) => changePage(pageNumber)} // Função para atualizar a página atual
        setRowsPerPage={(sizeNumber) => changeSize(sizeNumber)}
      />
    </>
  );
}
