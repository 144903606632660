import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1351B4",
    },
    secondary: {
      main: "#0C326F",
    },
    error: {
      main: "#E52207",
    },
    background: {
      default: "#0C326F",
    },
  },
  customColors: {
    white: "#FFFFFF",
    black: "#000000",
    blueWarm800: "#0C326F",
    blueWarm700: "#1351B4",
    blueWarm200: "#C5D4EB",
    greenWarm200: "#CBD17A",
    greyWarm50: "#F0F0EC",
    greyWarm100: "#E6E6E6",
    greyWarm200: "#CAC9C0",
    greyWarm400: "#888888",
    yellowVivid200: "#FFCD07",
    redVivid500: "#E52207",
  },
  typography: {
    fontFamily: "Rawline",
    allVariants: {
      color: "#000000",
    },
    h1: {
      fontSize: "2.612rem",
      fontWeight: 400,
    },
    h2: {
      fontSize: "1.25rem",
      fontWeight: 700,
    },
    h3: {
      fontSize: "1.831rem",
      fontWeight: 500,
    },
    h4: {
      fontSize: "2.12rem",
      fontWeight: 400,
    },
    p: {
      fontSize: "1rem",
      fontWeight: 400,
    },
    p2: {
      fontSize: "0.875rem",
      fontWeight: 600,
    },
    p3: {
      fontSize: "0.875rem",
      fontWeight: 400,
    },
    p4: {
      fontSize: "1rem",
      fontWeight: 500,
    },
    p6: {
      fontSize: "1rem",
      fontWeight: 600,
    },
    label: {
      fontSize: "0.875rem",
      fontWeight: 600,
    },
  },
  components: {
    MuiInput: {
      styleOverrides: {
        input: {
          "&::placeholder": {
            fontFamily: "Rawline",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          "&::placeholder": {
            fontFamily: "Rawline",
            fontSize: "0.875rem",
          },
        },
      },
    },
  },
});

export default theme;
