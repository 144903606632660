import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLoaderData, useNavigate } from "react-router-dom";
import { Bounce, toast } from "react-toastify";
import ButtonComponent from "../../components/ButtonComponent/ButtonComponent";
import TableComponent from "../../components/Table/TableComponent";
import TextArea from "../../components/TextArea/TextArea";
import InputComponent from "../../components/InputComponent/InputComponent";
import SelectComponent from "../../components/SelectComponent/SelectComponent";
import ModalEditAssociationReport from "./components/ModalEditAssociantionReport";
import ModalDeleteAssociationReport from "./components/ModalDeleteAssociationReport";
import GenericService from "../../services/GenericService";
import { formatDescription } from "./ReportDetails";

export const associationDetailsLoader = async ({ params }) => {
  try {
    const responseReport = await GenericService.findOne(
      "find-reporting",
      params.id,
    );

    const responseAssociation = await GenericService.findAllList(
      `reporting/${params.id}/pointInterest`,
    );

    if (responseReport.status === 200 && responseAssociation) {
      const transformedContent = responseAssociation.data.content.map(
        (item) => ({
          id: item.id,
          observation: item.observation,
          indicM: item.indicM,
          indicP: item.indicP,
          type: item.pointInterest.type.type,
          typeDescription: item.pointInterest.typeDescription,
          pointInterestId: item.pointInterest.id,
          typeId: item.pointInterest.type,
        }),
      );
      const transformedAssociationData = {
        ...responseAssociation.data,
        content: transformedContent,
      };

      return {
        reportDetails: responseReport.data,
        associations: transformedAssociationData,
      };
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error loading association details:", error);
  }
  return {};
};

const newAssociationFormValidationSchema = yup.object({
  type: yup.object(),
  typeDescription: yup.string(),
  indicM: yup.object(),
  indicP: yup.object(),
  observation: yup.string(),
});

const typeOptions = [
  { value: 1, name: "CPF", mask: "999.999.999-99" },
  { value: 2, name: "PLACA", mask: "*** 9*99" },
  { value: 3, name: "CONTAINER", mask: "****999999-9" },
  { value: 4, name: "ENDEREÇO" },
  { value: 5, name: "TELEFONE", mask: "(99)9999-99999" },
  { value: 6, name: "OUTROS" },
];

export default function AssociationReport() {
  const { handleSubmit, control, watch, reset } = useForm({
    resolver: yupResolver(newAssociationFormValidationSchema),
  });
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const { reportDetails } = useLoaderData();
  const [associations, setAssociations] = useState(
    useLoaderData().associations,
  );
  const [selectedAssociation, setSelectedAssociation] = useState(null);
  const navigate = useNavigate();

  const columns = [
    { id: "type", label: "Tipo" },
    { id: "typeDescription", label: "Dado" },
    { id: "indicM", label: "Índice M" },
    { id: "indicP", label: "Índice P" },
    { id: "observation", label: "Observações", format: formatDescription },
  ];

  const mAndPIndex = [
    { name: "1", value: 1 },
    { name: "2", value: 2 },
    { name: "3", value: 3 },
    { name: "4", value: 4 },
    { name: "5", value: 5 },
  ];

  const selectedType = watch("type");
  const typeSelected = selectedType
    ? typeOptions.find((option) => option.name === selectedType.name)
    : null;

  const handleReportView = () => {
    navigate(`/relatorios/${reportDetails.id}/editar-visualizar`);
  };

  const getAssociations = async () => {
    try {
      const responseAssociation = await GenericService.findAllList(
        `reporting/${reportDetails.id}/pointInterest`,
      );

      if (responseAssociation) {
        const transformedContent = responseAssociation.data.content.map(
          (item) => ({
            id: item.id,
            observation: item.observation,
            indicM: item.indicM,
            indicP: item.indicP,
            type: item.pointInterest.type.type,
            typeDescription: item.pointInterest.typeDescription,
            pointInterestId: item.pointInterest.id,
            typeId: item.pointInterest.type,
          }),
        );
        const transformedAssociationData = {
          ...responseAssociation.data,
          content: transformedContent,
        };

        setAssociations(transformedAssociationData);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Error loading associations:", error);
    }
  };

  const handleNewAssociation = async (data) => {
    const payload = {
      reportingOrderId: reportDetails.id,
      type: data.type.name,
      typeDescription: data.typeDescription,
      indicM: data.indicM.value,
      indicP: data.indicP.value,
      observation: data.observation,
    };

    try {
      const response = await GenericService.create(
        "reporting/pointInterest",
        payload,
      );
      // eslint-disable-next-line no-constant-condition
      if (response.status === 200) {
        toast.success("Associação criada com sucesso!", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        reset();
        await getAssociations();
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Error:", error);
    }
  };

  const handleAssociationFocus = (id) => {
    const associationFocus = associations.content.find(
      (association) => association.id === id,
    );
    setSelectedAssociation(associationFocus);
  };

  const handleDeleteAssociation = async (id) => {
    try {
      const response = await GenericService.deleteOne(
        `reporting/pointInterest/${id}`,
      );
      if (response.status === 200) {
        toast.success("Associação excluida com sucesso!", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        setOpenModalDelete(false);
        await getAssociations();
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Error:", error);
    }
  };

  const handleEditAssociation = async (data) => {
    const payload = {
      id: selectedAssociation.id,
      observation: data.observation,
      indicM: data.indicM.value,
      indicP: data.indicP.value,
      pointInterest: {
        id: selectedAssociation.pointInterestId,
        typeDescription: data.typeDescription,
        type: {
          id: data.type.value,
          type: data.type.name,
        },
      },
    };

    try {
      const response = await GenericService.update(
        "reporting/pointInterest",
        payload,
      );
      // eslint-disable-next-line no-constant-condition
      if (response.status === 200) {
        toast.success("Associação editada com sucesso!", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });

        await getAssociations();
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Error:", error);
    }
  };

  return (
    <Box>
      <Box marginY={3}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          gap={3}
        >
          <Typography variant="h4">Gerenciar associações</Typography>
          <Box display="flex" gap={2}>
            <ButtonComponent
              title="Ir para o relatório"
              icon="file"
              onClick={() => handleReportView()}
            />
          </Box>
        </Box>
        <Box marginTop={1}>
          <Typography variant="p3">
            Relatório #{reportDetails?.id || "NA"} -{" "}
            {reportDetails?.title || "NA"}
          </Typography>
        </Box>
      </Box>
      <Grid container display="flex" gap={3}>
        <Grid container xs={8} spacing={3} padding={3}>
          <TableComponent
            columns={columns}
            data={associations}
            {...(reportDetails?.status === "Em elaboração" && {
              actions: ["edit", "delete"],
              onClickEdit: (data) => {
                handleAssociationFocus(data);
                setOpenModalEdit(true);
              },
              onClickDelete: (data) => {
                handleAssociationFocus(data);
                setOpenModalDelete(true);
              },
            })}
          />
        </Grid>
        <Grid
          item
          xs={4}
          display="flex"
          flexDirection="column"
          gap={5}
          alignSelf="flex-start"
        >
          {reportDetails?.status === "Em elaboração" && (
            <Box boxShadow={2} padding={2}>
              <Typography variant="h2">Nova associação</Typography>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                gap={1}
              >
                <form onSubmit={handleSubmit(handleNewAssociation)}>
                  <Box>
                    <Controller
                      name="type"
                      control={control}
                      defaultValue=""
                      render={({ field, fieldState: { error } }) => (
                        <SelectComponent
                          label="Tipo"
                          placeholder="Selecionar item"
                          options={typeOptions}
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name="typeDescription"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <InputComponent
                          {...field}
                          placeholder={typeSelected ? typeSelected.name : ""}
                          mask={typeSelected ? typeSelected.mask : undefined}
                        />
                      )}
                    />
                  </Box>
                  <Box display="flex" gap={0} justifyContent="space-between">
                    <Controller
                      name="indicM"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <SelectComponent
                          label="Índice M"
                          placeholder="Selecionar item"
                          options={mAndPIndex}
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name="indicP"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <SelectComponent
                          label="Índice P"
                          placeholder="Selecionar item"
                          options={mAndPIndex}
                          {...field}
                        />
                      )}
                    />
                  </Box>
                  <Controller
                    name="observation"
                    control={control}
                    defaultValue=""
                    render={({ field, fieldState: { error } }) => (
                      <TextArea
                        label="Observação"
                        placeholder="Insira sua observação..."
                        maxLength={500}
                        {...field}
                      />
                    )}
                  />
                  <Box display="flex" justifyContent="flex-end" marginTop={1}>
                    <ButtonComponent
                      buttonType="secondary"
                      title="Adicionar"
                      icon="new"
                      submit
                    />
                  </Box>
                </form>
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
      <ModalEditAssociationReport
        openModal={openModalEdit}
        closeModal={() => {
          setOpenModalEdit(false);
          setSelectedAssociation(null);
        }}
        association={selectedAssociation}
        onFormSubmit={handleEditAssociation}
      />
      <ModalDeleteAssociationReport
        openModal={openModalDelete}
        closeModal={() => {
          setOpenModalDelete(false);
          setSelectedAssociation(null);
        }}
        association={selectedAssociation}
        handleDelete={handleDeleteAssociation}
      />
    </Box>
  );
}
