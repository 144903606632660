import { toast, Bounce } from "react-toastify";

// Configuração padrão para o toast
const defaultToastConfig = {
  position: "bottom-right",
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
  transition: Bounce,
};

/**
 * Exibe um toast de sucesso.
 * @param {string} message - A mensagem a ser exibida.
 * @param {object} [config] - Configurações adicionais para o toast.
 */
export const showSuccessToast = (message, config = {}) => {
  toast.success(message, { ...defaultToastConfig, ...config });
};

/**
 * Exibe um toast de erro.
 * @param {string} message - A mensagem de erro a ser exibida.
 * @param {object} [config] - Configurações adicionais para o toast.
 */
export const showErrorToast = (message, config = {}) => {
  toast.error(message, { ...defaultToastConfig, ...config });
};

/**
 * Exibe um toast de alerta (informativo).
 * @param {string} message - A mensagem de alerta a ser exibida.
 * @param {object} [config] - Configurações adicionais para o toast.
 */
export const showAlertToast = (message, config = {}) => {
  toast.info(message, { ...defaultToastConfig, ...config });
};
