/* eslint-disable guard-for-in */
import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
  Tooltip,
  ToggleButton,
  IconButton,
  Box,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CloseIcon from "@mui/icons-material/Close";
import StyledToggleButtonGroup from "./StyledToggleButtonGroup";
import MessageComponent from "../MessageComponent/MessageComponent";
import { showErrorToast } from "../Toast/Toast";

function ImageToolbarEditor(props) {
  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState("");
  const [file, setFile] = useState(null);
  const [referencia, setReferencia] = useState("");
  const [disableButton, setDisableButton] = useState(true);
  const [showMessage, setShowMessage] = useState(false);

  const { editor, icon } = props;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setOpen(false);
    setUrl("");
    setFile(null);
    setReferencia("");
    setShowMessage(false);
  };

  const validSVG = async (svgFile) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      // eslint-disable-next-line func-names
      reader.onload = function (event) {
        const svg = event.target.result;
        const parse = new DOMParser();
        const svgDoc = parse.parseFromString(svg, "image/svg+xml");
        const svgElement = svgDoc.documentElement;
        if (
          svgElement.hasAttribute("height") &&
          svgElement.hasAttribute("width")
        ) {
          resolve(svgFile);
        } else {
          const viewBox = svgElement.getAttribute("viewBox");
          const arr = viewBox.split(" ");
          svgElement.setAttribute("width", arr[arr.length - 2]);
          svgElement.setAttribute("height", arr[arr.length - 1]);
          const blob = new Blob([svgElement.outerHTML], {
            type: "image/svg+xml",
          });
          const newFile = new File([blob], svgFile.name, {
            type: "image/svg+xml",
          });
          resolve(newFile);
        }
      };
      reader.onerror = reject;
      reader.readAsText(svgFile);
    });

  const handleFileChange = async (e) => {
    const input = e.target;
    setShowMessage(false);

    if (input.files && input.files[0]) {
      const fileImage = input.files[0];
      const maxFileSize = 25 * 1024 * 1024; // 40MB em bytes

      if (fileImage.size > maxFileSize) {
        showErrorToast("O tamanho da imagem não pode exceder 25MB!");
        setDisableButton(true);
        setFile(null);
        return;
      }

      const allowedFormats = [
        "image/jpeg",
        "image/png",
        "image/svg+xml",
        "image/bmp",
      ];
      if (allowedFormats.includes(fileImage.type)) {
        if (fileImage.type === "image/svg+xml") {
          const svgValidated = await validSVG(fileImage);
          setFile(svgValidated);
        } else {
          setFile(fileImage);
        }
        setUrl("");
        setDisableButton(false);
      } else {
        showErrorToast(
          "O formato da imagem deve ser JPG, PNG, SVG, BMP ou JPEG!",
        );
        setDisableButton(true);
        setFile(null);
        setShowMessage(true);
      }
    }

    input.value = ""; // Limpando o input
  };

  // eslint-disable-next-line consistent-return
  const getBase64FromImageUrl = async (urlAux) => {
    try {
      const response = await fetch(
        `https://corsproxy.io/?${encodeURIComponent(urlAux)}`,
      );
      if (response && response.status === 200) {
        const blob = await response.blob();
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        setDisableButton(false);
        return new Promise((resolve) => {
          reader.onloadend = () => {
            resolve(reader.result);
          };
        });
      }
    } catch (error) {
      setDisableButton(true);
      setShowMessage(true);
      setUrl("");
    }
  };

  const getBase64FromImageFile = async (blobUrl) => {
    const response = await fetch(blobUrl);
    const blob = await response.blob();

    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise((resolve, reject) => {
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
    });
  };

  const addImage = async (e) => {
    let src = "";
    if (url) {
      src = await getBase64FromImageUrl(url);
    } else {
      const blob = URL.createObjectURL(file);
      src = await getBase64FromImageFile(blob);
    }

    if (src) {
      editor.commands.insertContent([
        {
          type: "image",
          attrs: {
            alt: null,
            src,
            title: null,
            reference: referencia,
            alignItems: "center",
          },
        },
      ]);
      handleClose(e);
    } else {
      setShowMessage(true);
    }
  };

  const handleRemoveFile = () => {
    setFile(null);
    setDisableButton(true);
    setUrl("");
  };

  return (
    <StyledToggleButtonGroup size="small" sx={{ alignItems: "center" }}>
      <Tooltip title="Inserir imagem" placement="top">
        <ToggleButton
          value="image"
          aria-label="image"
          onClick={handleClickOpen}
          sx={{
            width: 40,
            height: 40,
            gap: "5px",
            padding: "0px 10px 0px 10px",
            borderRadius: "4px",
            border: "1px solid #888888 !important",
            minWidth: 0,
          }}
        >
          <FontAwesomeIcon icon={icon} />
        </ToggleButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Inserir Imagem</DialogTitle>
        <Box
          sx={{
            width: "100%",
            height: "5px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: 3,
          }}
        >
          {showMessage && (
            <MessageComponent
              type="Erro"
              message="Formato da imagem inválida!"
              handleClose={() => {
                setShowMessage(false);
              }}
              sx={{ width: "100%", textAlign: "center" }}
            />
          )}
        </Box>
        <DialogContent
          sx={{
            width: "350px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <TextField
            autoFocus
            margin="dense"
            id="fullWidth"
            label="Imagem URL"
            placeholder="Insira a URL da imagem"
            type="url"
            fullWidth
            variant="standard"
            value={url}
            onChange={(event) => {
              setUrl(event.target.value);
              setDisableButton(false);
            }}
            disabled={file !== null}
          />
          <div style={{ textAlign: "center" }}>OU</div>
          <div
            className="file-input-container"
            style={{
              textAlign: "center",
              margin: "10px 0",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label
              className="file-label"
              style={{
                display: "inline-block",
                padding: "10px 20px",
                backgroundColor: "#1351B4",
                color: "white",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              Selecionar imagem
              <input
                type="file"
                className="file-input"
                onChange={handleFileChange}
                accept=".jpg,.jpeg,.png,.svg,.bmp"
                hidden="true"
                style={{
                  position: "relative",
                  top: 0,
                  left: 0,
                  width: "100%",
                  opacity: 0,
                  cursor: "pointer",
                }}
              />
            </label>
            {file && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                <span>{file.name}</span>
                <IconButton
                  onClick={handleRemoveFile}
                  size="small"
                  sx={{ marginLeft: "10px" }}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </div>
            )}
          </div>

          <Divider flexItem orientation="horizontal" sx={{ mx: 0.5, my: 1 }} />
          <Box>
            <TextField
              required
              margin="dense"
              id="ref"
              label="Referência"
              placeholder="Referência da Imagem"
              type="text"
              fullWidth
              variant="standard"
              inputProps={{ maxLength: 255 }}
              value={referencia}
              onChange={(event) => setReferencia(event.target.value)}
            />
            <Typography variant="body2" color="textSecondary" align="right">
              {referencia.length}/255
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button disabled={!referencia || disableButton} onClick={addImage}>
            Inserir
          </Button>
        </DialogActions>
      </Dialog>
    </StyledToggleButtonGroup>
  );
}

export default ImageToolbarEditor;
